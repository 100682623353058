import { createRouter, createWebHistory } from 'vue-router'

import routes from '~pages'
import { hasEntityAction, hasProjectAction, hasRole } from '@/composables/useAuth'
import { projectKey } from '@/composables/useProject'

const isDevelopment = process.env.NODE_ENV !== 'production'

const router = createRouter({
  routes,
  history: createWebHistory(),
})

const orArray = v => (Array.isArray(v) ? v : [v])

const isOkForRoute = ({
  meta: {
    requiredRole = undefined,
    requiredProjectActions = undefined,
    requiredEntityActions = undefined,
  },
}) => {
  // route does not require any roles
  if (!requiredRole && !requiredProjectActions && !requiredEntityActions) return true
  // is one of roles check passed all is OK
  return (
    (requiredRole && orArray(requiredRole).some(hasRole)) ||
    (requiredProjectActions &&
      orArray(requiredProjectActions).some(action => hasProjectAction(projectKey.value, action))) ||
    (requiredEntityActions &&
      orArray(requiredEntityActions).some(([entity, action]) =>
        hasEntityAction(projectKey.value, entity, action)
      ))
  )
}

const isRolesPassed = ({ to: { matched = [] } }) => {
  const val = !matched.some(v => !isOkForRoute(v))
  if (!val) router.push({ path: '/' })
  return val
}

const isDevelopmentCheckPassed = ({ to: { matched = [] } }) =>
  !matched.some(({ meta: { devOnly = false } }) => devOnly && !isDevelopment)

router.beforeEach((to, from, next) => {
  if (isRolesPassed({ to }) && isDevelopmentCheckPassed({ to })) {
    return next()
  }
})

router.afterEach(to => {
  let titleV = ''
  to.matched.forEach(({ meta: { title } }) => {
    if (title) {
      titleV = title
    }
  })
  document.title = titleV
})

export default router
